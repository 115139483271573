
import './App.css';
import FrancisXavier from './pages/FrancisXavier';
import Hero from './pages/Hero';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">

      <Router  >
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path={"/home"} element={<FrancisXavier />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;
