import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'




const FrancisXavier = () => {
  return (
    <main className="bg-black overflow-hidden">
    <Navbar />
    <Hero />
  </main>
  )
}

export default FrancisXavier