import React from "react"

const Hero = () => {


  return (
    <section className="w-full h-screen bg-black relative overflow-hidden">
      <div className=" w-full flex-center flex-col  pb-10 border-b">
        <h1 className="text-3xl text-white py-10 px-10">About Sampaloor Church</h1>
        <div className=" text-white px-10 text-lg" >
          {/* <video className="pointer-events-none" autoPlay muted playsInline={true} key={videoSrc}>
            <source src={videoSrc} type="video/mp4" />
          </video> */}
          Near the banks of the Chalakudy River is Sampaloor, popularly described as the Goa of Kerala due to it bearing witness to the arrival of a series of clergy. St. Francis Xavier, St. John Britto, Fr. Joseph Constantine Buski (Veeramamunivar) and Arnos Pathiri are all eminent clergymen who arrived at Sampaloor, located in the district of Thrissur. The first to arrive among them was St. Francis Xavier, who came via Marankuzhi, a place located in Kodungalloor. According to records, his visits took place in 1542, 1544, and 1548. At that time the Muziris harbour (present day Kodungalloor) was the gateway connecting India to foreign lands.
          <br /><br />
          An important centre of the Jesuits priests during the reign of the Portuguese, Sampaloor still contains numerous monuments from that era. They include the St. Paul's Seminary, the St. Paul's Monastery and the St. Paul's Press and a church dedicated to St. Francis Xavier. The name Sampaloor itself finds its roots in San-paul-ur. Mid-17th century saw the arrival of St. John Britto, an Italian missionary who adopted an Indian name - Swamy Arulananda. The altar of St. Francis Xavier's church at which St. John Britto used to celebrate the holy mass is now a protected historical monument. The next member of the clergy who played a prominent role in the history of Sampaloor was Arnos Pathiri, who studied at the St. Paul's Seminary and later got ordained there.He made remarkable contributions to Malayalam literature through his book 'Puthen Pana'. In his footsteps followed Fr. Joseph Constantine Buski (Veeramamunivar) who came to Sampaloor, while serving in the Madurai Mission and was later laid to rest here.
          <br /><br />
          Sampaloor became an eminent centre of nurturing language in South India, with the first Malayalam printing being done here at the St. Paul's Press, which was set up by Jesuit Priests in 1663. Another integral part of Sampaloor’s eventful history was the attack mounted by Tipu Sultan, who was fighting the British during the middle periods of the 18th century, which led to the destruction of numerous institutions of historical prominence. The church was razed to the ground, but was reconstructed the time of Bernadian Bechinelly, who was the vicar apostolate of Varapuzha in 1862 and the present church was blessed in 1979. The Historical Museum of Sampaloor and the Anglo-Indian Cultural Museum both have numerous artefacts that help you understand the unique history of this region.
        </div>
      </div>
      <div className='text-white'>


      </div>

      {/* <div
        id="cta"
        className="flex flex-col items-center opacity-0 translate-y-20"
      >
        <a href="#highlights" className="btn">Buy</a>
        <p className="font-normal text-xl">From $199/month or $999</p>
      </div> */}
    </section>
  )
}

export default Hero