import React, { useState, useRef } from 'react';
import stageOpening from '../assets/videos/stage_opening.MP4';
import ContentCutSharpIcon from '@mui/icons-material/ContentCutSharp';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }

    
   setTimeout(() => {    
    navigate("/home");
   }, 11000);
  };

  

  

  return (
    <section className="w-full flex flex-col justify-center items-center h-screen relative">
      <video
        className="cursor-auto h-screen  w-full object-cover "
        ref={videoRef}
        autoPlay={false}
        muted
        playsInline={true}
        key={stageOpening}
      // style={{ display: isPlaying ? 'block' : 'block' }}
      >
        <source src={stageOpening} type="video/mp4" />
      </video>
      {!isPlaying && (
        <IconButton
          onClick={handlePlayButtonClick}
          className='absolute !important top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        >
          <ContentCutSharpIcon style={{ fontSize: '65px' }} className=' text-gray-300' />

        </IconButton>
      )}

    </section>
  );
}

export default Hero;
